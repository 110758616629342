import * as React from 'react'
import { DirectionType, DirectionTypesOptions, TourDirectionModel, TourManageModel } from '../../models/TourManageModel'
import { FormFieldWrapper } from 'traveldesk-ui'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'traveldesk-ui/dist/store'
interface Props {
    tour?: TourManageModel
    directions: TourDirectionModel[]
    onChange: (directions: TourDirectionModel[]) => void
}
const TourFormDirections = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const onChange = (type: DirectionType) => (e: any) => {
        const newDirections = e.target.value
            ? props.directions.some(x => x.type == type)
                ? props.directions.map(x => x.type == type
                    ? Object.assign(TourDirectionModel.Create(x), { description: e.target.value })
                    : x)
                : props.directions.concat([Object.assign(new TourDirectionModel(), { tourId: props.tour?.id ?? 0, type, description: e.target.value })])
            : props.directions.filter(x => x.type != type)
        props.onChange(newDirections)
    }
    return <>
        <FormFieldWrapper>
            <label>Directions: </label>
        </FormFieldWrapper>
        {DirectionTypesOptions.map(type => <FormFieldWrapper>
            <label>{type.label}</label>
            <input type="text" value={props.directions.find(x => x.type == type.value)?.description} onChange={onChange(type.value)} />
            <br />
        </FormFieldWrapper>
        )}
    </>
}
export default TourFormDirections